import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modalStyle.scss";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import Customselect from "../CustomSelect";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import ValidateEmail from "../../Helper/emailValidation";
import Loader from "../../Helper/Loader";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";

let LINE_OF_SERVICES = [
  { value: "Solar", label: "Solar" },
  { value: "Solar Module Line", label: "Solar Module Line" },
  { value: "Biomass (Punjab & Haryana)", label: "Biomass (Punjab & Haryana)" },
  { value: "Biomass- Rajasthan", label: "Biomass- Rajasthan" },
  { value: "HO", label: "HO" },
];

const EditUserModal = (props) => {
  const { userData, roles } = props;
  const { mainStore } = useStores();
  const [name, setName] = useState(userData?.name);
  const [contact, setContact] = useState(userData?.contact);
  const [email, setEmail] = useState(userData?.email);
  const [empCode, setEmpCode] = useState(userData?.emp_code);
  const [password, setPassword] = useState("********");
  const [selectedRoles, setSelectedRoles] = useState(props.selectedRoles);
  const [userDepartment, setUserDepartment] = useState(
    userData?.departmentId?.department_id
  );
  const [userDesignation, setUserDesignation] = useState(
    userData?.designationId?.designation_id
  );
  const [selectedLineOfService, setSelectedLineOfService] = useState(
    userData?.line_of_service
  );
  const [rolesList, setRolesList] = useState([])
  const [ischecked, setIsChecked] = useState(userData?.is_interviewer && userData?.is_interviewer)
  const [dateOfJoining, setDateOfJoining] = useState(userData?.date_of_joining && userData?.date_of_joining.split("T")[0])
  const [isLoading, setIsLoading] = useState(false)
  const [userEditBusiness, setUserEditBusiness] = useState(userData?.businessId?.business_id)
  const [selectedSubCategory, setSelectedSubCategory] = useState(props?.selectedSubCat)
  const [selectedCategory, setSelectedCategory] = useState(props?.selectedCat)
  const [selectedReportingManager, setSelectedReportingManager] = useState(userData?.reporting_manager)
  const [update, setUpdagte] = props?.subcategoryList
  console.log(props?.selectedSubCat, "defaultValue");



  const setdata = async () => {
    let ids = userData?.category?.map((ele) => {
      return (ele?.category_id)
    });
    await props?.fetchSubCategory(ids);

  }
  useEffect(() => {
    if (userData) {
      setdata();
    }

  }, [userData])

  useEffect(() => {
    //  purchase deparment that id is 7
    if (userDepartment == 7) {
      let rolesData = roles.filter(obj => obj.label !== "Treasury");
      setRolesList(rolesData)

    }

    if (userDepartment !== "7") {
      setRolesList(roles)
    }

  }, [userDepartment])


  const findUserDeparmentData = () => {
    let Data;
    Data = props?.departmentData.find((elem) => elem.value == userDepartment)
    if (Data?.label == "finance") {
      return "Finance"
    } else if (Data?.label == "purchase") {
      return "Purchase"
    }

    return Data?.label
  }

  const editUser = async () => {
    if (name === "undefined" || name === "" || name === "null") {
      return notify("Please Enter Name", "danger");
    }
    if (contact === "undefined" || contact === "" || contact === "null") {
      return notify("Please Enter Contact", "danger");
    }
    if (email === "undefined" || email === "" || email === "null") {
      return notify("Please Enter Email", "danger");
    }
    if (empCode === "undefined" || empCode === "" || empCode === "null") {
      return notify("Please Enter Emp Code", "danger");
    }
    if (
      userDepartment === "undefined" ||
      userDepartment === "" ||
      userDepartment === "null"
    ) {
      return notify("Please Select Department", "danger");
    }
    if (selectedRoles.length == 0) {
      return notify("Please Select Roles", "danger");
    }

    let selectedrolesValue = [];
    for (let i = 0; i < selectedRoles.length; i++) {
      selectedrolesValue.push(selectedRoles[i].value);
    }
    let selectedcatValue = [];
    for (let i = 0; i < selectedCategory.length; i++) {
      selectedcatValue.push(selectedCategory[i].value);
    }
    let selectedsubcatValue = [];
    for (let i = 0; i < selectedSubCategory.length; i++) {
      selectedsubcatValue.push(selectedSubCategory[i].value);
    }

    const getRoleLabels = (selectedRoleIds, roles) => {
      return roles
        .filter(role => selectedRoleIds.includes(role.value))
        .map(role => role.label);
    };

    const selectedRoleLabels = getRoleLabels(selectedrolesValue, roles);

    const roleIncludes = (i, checkRoles) => {
      return i.some(role => checkRoles.includes(role));
    };
    const requiredLosRoles = ['Requestor', 'Approver', 'Treasury'];
    const optionalReportingManagerRoles = ['CEO', 'Director', 'MD'];
    

    let payload = {
      name: name,
      contact: contact,
      email: email,
      empCode: empCode,
      roles: selectedrolesValue,
      isActive: true,
      userDepartment: findUserDeparmentData(),
      is_interviewer: ischecked,
      department_id: userDepartment,
      designation_id: userDesignation,
      date_of_joining: dateOfJoining,
    };

    if (selectedcatValue.length > 0) {
      payload.category_id = selectedcatValue
    }
    if (selectedsubcatValue.length > 0) {
      payload.subcategory_id = selectedsubcatValue
    }
    // if (selectedReportingManager) {
    //   payload.reporting_manager = selectedReportingManager
    // }
    // if (selectedLineOfService) {
    //   payload.lineOfService = selectedLineOfService
    // }
    if (roleIncludes(selectedRoleLabels, requiredLosRoles)) {
      if (selectedLineOfService) {
        payload.lineOfService = selectedLineOfService;
      } else {
        return notify('Line of Service is required for the selected roles.',"danger");
      }
    } else {
      if (selectedLineOfService) {
        payload.lineOfService = selectedLineOfService; 
      }
    }
    
   
    if (!roleIncludes(selectedRoleLabels, optionalReportingManagerRoles)) {
      if (selectedReportingManager) {
        payload.reporting_manager = selectedReportingManager;
      } else {
        return notify('Reporting Manager is required for the selected roles.' , "danger");
      }
    } else {
      if (selectedReportingManager) {
        payload.reporting_manager = selectedReportingManager; 
      }
    }



    if (userEditBusiness) {
      payload.business_id = userEditBusiness
    }

    if (password !== "********" && password !== "") {
      payload.password = password;
    }
    setIsLoading(true)
    await mainStore.editUser(
      userData?.id,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          props.onHideModal();
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  const handleEmailOnBlur = (e) => {
    let validate = ValidateEmail(e);
    if (validate === false) {
      notify("Please Enter A Valid Email ID", "danger");
      setEmail("");
      return;
    } else {
      setEmail(e);
    }
  };


  const handleCategory = (e) => {
    debugger
    let item = [];
    for (let i = 0; i < e?.length; i++) {
      item.push(e[i]);
    }
    setSelectedCategory(item);
    // props?.fetchSubCategory(item?.map((ele) =>{
    //   return ele?.value
    // }))
    const subCategoryValues = e.map((ele) => ele?.value);

    if (props?.fetchSubCategory) {
      props.fetchSubCategory(subCategoryValues);
    }
  }

  const handleSubCategory = (e) => {
    let data = [];
    for (let i = 0; i < e.length; i++) {
      data.push(e[i]);
    }
    setSelectedSubCategory(data)
  }

  debugger


  return (
    <Modal {...props} centered className="sucess-content">
      {isLoading && <Loader />}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-4">
            <CustomInput
              label={"Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder={"Enter Name"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Contact"}
              value={contact}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  setContact(e.target.value);
                }
              }}
              placeholder={"Enter Contact"}
            />
          </div>
          <div className="col-md-4 login-text">
            <CustomInput
              label={"Email"}
              value={email}
              onBlur={(e) => {
                handleEmailOnBlur(e.target.value);
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"Enter Email"}
            />
          </div>
          <div className="col-md-4">
            <CustomInput
              label={"Emp Code"}
              value={empCode}
              onChange={(e) => {
                setEmpCode(e.target.value);
              }}
              placeholder={"Enter Emp Code"}
            />
          </div>
          <div className="col-md-4 login-text">
            <CustomInput
              label={"Password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder={"Enter Password"}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Business"}
              value={userEditBusiness}
              options={props?.businessList}
              onChange={(e) => {
                setUserEditBusiness(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Department"}
              value={userDepartment}
              options={props?.departmentData}
              onChange={(e) => {
                setUserDepartment(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Select Designation"}
              value={userDesignation}
              options={props?.designationData}
              onChange={(e) => {
                setUserDesignation(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              multiSelect={true}
              label={"Roles"}
              value={selectedRoles}
              options={userDepartment === "" ? props?.roles : rolesList}
              defaultValue={selectedRoles}
              onChange={(e) => {
                setSelectedRoles(e);
              }}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Line Of Service"}
              value={selectedLineOfService}
              options={LINE_OF_SERVICES}
              onChange={(e) => {
                setSelectedLineOfService(e.target.value);
              }}
            />
          </div>
          <div className="col-md-4 login-text">
            <CustomInput
              label={"DOJ"}
              // require ={true}
              value={dateOfJoining}
              onChange={(e) => {
                setDateOfJoining(e.target.value);
              }}
              placeholder={"DOJ"}
              type="date"
            />
          </div>
          <div className="col-md-4">
            <Customselect
              label={"Reporting Manager"}
              options={props?.reportingManager}
              value={selectedReportingManager}
              onChange={(e) => setSelectedReportingManager(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <Customselect
              multiSelect={true}
              label={"Category"}
              options={props?.categoryList}
              value={props?.selectedCat}
              defaultValue={props?.selectedCat}
              onChange={handleCategory}
            />
          </div>
          <div className="col-md-4">
            {props?.selectedSubCat?.length !== 0 && (
              <Customselect
                multiSelect={true}
                label={"Sub Category"}
                options={props?.subCategoryList}
                value={props?.selectedSubCat}
                defaultValue={props?.selectedSubCat}
                onChange={handleSubCategory}
              />
            )}
            {props?.selectedSubCat?.length === 0 && (
              <Customselect
                multiSelect={true}
                label={"Sub Category"}
                options={props?.subCategoryList}
                value={props?.selectedSubCat}
                defaultValue={props?.selectedSubCat}
                onChange={handleSubCategory}
              />
            )}

          </div>

          <div className="col-md-4 check-box">
            <Checkbox
              icon={<Icon.FiCheck color="#dc3545" size={20} />}
              name="my-input"
              checked={ischecked}
              onChange={(e) => setIsChecked(!ischecked)}
              borderColor={ischecked ? '#dc3545' : "#cccccc"}
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
              labelStyle={{ marginLeft: 5, userSelect: "none" }}
              label="Interviewer"
            />
          </div>
          <div className="text-center mt-3">
            <CustomButton
              styles={{ width: 120, height: 38, fontSize: 14 }}
              onClick={() => {
                editUser();
              }}
              children={"Submit"}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;
