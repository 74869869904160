import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import CustomCard from "../../components/CustomCard";
import CustomButton from "../../components/Button/Button";
import ResponsivePagination from "react-responsive-pagination";
import AddBankModal from "../../components/Modal/BankDetailModal/addBankModal";
import EditBankModal from "../../components/Modal/BankDetailModal/editBankModal";
import CustomInput from "../../components/CustomInput";
import Loader from "../../Helper/Loader";

const BankDetails = (props) => {
    const { mainStore } = useStores();
    const [entityList, setEntityList] = useState([]);
    const [bankDataList, setBankDataList] = useState([]);
    const [showBankModal, setShowBankModal] = useState(false);
    const [showEditBankModal, setShowEditBankModal] = useState(false);
    const [bankData, setBankData] = useState("");
    const [bankName, setBankName] = useState("");
    const [totalPage, setTotalPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let loginUserData = sessionStorage.getItem("userData");
    let parseuserData = JSON.parse(loginUserData);
    const[isLoading , setIsLoading] = useState(false)

    useEffect(() => {
        fetchBankDetails();
        fetchEntitiesList();
    }, []);

    const fetchEntitiesList = async () => {
        await mainStore.fetchEntitiesList((status, response) => {
            if (response?.meta?.status === 'success') {
                let data = response?.data?.entitites;
                let entities = [];
                for (let i = 0; i < data.length; i++) {
                    entities.push({ label: data[i].name, value: data[i].entity_code })
                }
                setEntityList(entities);
            } else {
                setEntityList([])
            }
        }, (error) => {
            setEntityList([])
            notify(error?.response?.meta?.message, "danger");
        })
    }


    const fetchBankDetails = async (page) => {
        let payload = {
            pageNumber: page ? page : 1,
            limit: 10,
            filters: {},
        };
        if (bankName) {
            payload.filters.bankName = bankName;
        }
        setIsLoading(true)
        await mainStore.fetchBankDetails(
            payload,
            (response) => {
                if (response?.data?.meta?.status === "success") {
                    let detailList = response?.data?.data?.filteredData?.results;
                    setBankDataList(detailList);
                    setTotalPage(response?.data?.data?.totalPages);
                    setIsLoading(false)
                } else {
                    setBankDataList([]);
                    setTotalPage(null);
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)

                }
            },
            (error) => {
                setBankDataList([]);
                setTotalPage(null);
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false)

            }
        );
    };

    const fetchBankData = async (id) => {
        await mainStore.getBankData(
            id,
            (status, response) => {
                if (response?.meta?.status === "success") {
                    let data = response?.data?.bankData;
                    setBankData(data);
                    setShowEditBankModal(true);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                }
            },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
            }
        );
    };

    const handlePageChange = (pageNumber) => {
        fetchBankDetails(pageNumber);
        setCurrentPage(pageNumber);
    };

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <div className="dashboard-container">
                <div className="right-view">
                    <Header title={`Welcome ${parseuserData?.name},`} />
                    <div className="mt-3 login-text">
                        <CustomCard
                            cardtitle={"Bank Detail"}
                            cardboldtitle={"List"}
                            searchName={
                                <CustomInput
                                    placeholder={"Search Bank Name"}
                                    value={bankName}
                                    onChange={(e) => {
                                        setBankName(e.target.value);
                                    }}
                                />
                            }
                            toprightview={
                                <CustomButton
                                    onClick={() => {
                                        setShowBankModal(true);
                                    }}
                                    children={"Add Bank Details"}
                                />
                            }
                            cardbody={
                                <div className="container-fluid px-0">
                                    <div class="table-responsive listing-table">
                                        <table class="table ">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th scope="col">S.NO</th>
                                                    <th scope="col">Entity Name</th>
                                                    <th scope="col">Entity Code</th>
                                                    <th scope="col">Bank Name</th>
                                                    <th scope="col">Bank Account Number</th>
                                                    <th scope="col">Gl Code</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bankDataList?.length > 0 &&
                                                    bankDataList.map((item, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.entities[0]?.name}</td>
                                                                <td>{item?.entity_code}</td>
                                                                <td>{item?.bank_name}</td>
                                                                <td>{item?.bank_account_number}</td>
                                                                <td>{item?.glcode}</td>
                                                                <td>
                                                                    {item?.is_active ? "Active" : "Inactive"}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            fetchBankData(item?.id);
                                                                        }}
                                                                        width={20}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={require("../../assets/images/edit.png")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                        {entityList?.length == 0 && (
                                            <p className="page-not-found" style={{ height: 200 }}>
                                                No Records Found
                                            </p>
                                        )}
                                    </div>
                                    {entityList?.length > 0 && (
                                        <div className="pagination-container">
                                            <ResponsivePagination
                                                current={currentPage}
                                                total={totalPage}
                                                onPageChange={(e) => {
                                                    handlePageChange(e);
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                        />
                    </div>
                </div>
                <AddBankModal
                    show={showBankModal}
                    onHide={() => {
                        setShowBankModal(false);
                    }}
                    onHideModal={() => {
                        setShowBankModal(false);
                        fetchBankDetails();
                    }}
                    entitiesList={entityList}

                />
                <EditBankModal
                    show={showEditBankModal}
                    onHide={() => {
                        setShowEditBankModal(false);
                    }}
                    onHideModal={() => {
                        setShowEditBankModal(false);
                        fetchBankDetails();
                    }}
                    bankData={bankData}
                    key={bankData?.id}
                    entitiesList={entityList}
                />
            </div>
        </React.Fragment>
    );
};

export default BankDetails;
